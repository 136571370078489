import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserLoggedInGuard, UserNotLoggedInGuard, cartGuard, featureGuard } from './shared/guards';
import { ShellUser, ShellSales, ShellAuth } from './shells';
import { shippingAddressesCleanup, shippingAddressesLoaded } from './store/shipping-addresses';
import { productsCleanup } from './store/products';
import { productsPricingCleanup } from './store/product-pricing';
import { productCleanup } from './store/product';
import { shoppingCartCleanup, shoppingCartLoaded } from './store/shopping-cart';
import { favouritesCleanup, favouritesLoaded } from './store/favourites';
import { productCategoriesCleanup } from './store/product-categories';
import { productsSubstitutesCleanup } from './store/product-substitutes';
import { userLoaded } from './store/user';
import { mediaCleanup } from './store/media';

// #FIXME - remove after multiproject setup
import { adminGuard } from './shared/guards/admin/admin.guard';
import { panelGuard } from './shared/guards/admin/panel.guard';
import { cleanupBlockades } from './modules/blockades/guards/cleanup.guard';
import { cleanupAddressAlert } from './products/guards/cleanup.guard';
import { addressAlertGuard } from './products/guards/address-alert.guard';
import { productFiltersCleanup } from './store/product-filters/guards/cleanup.guard';
import { shoppingListsCleanup } from './store/shopping-lists';
import { shoppingListCleanup } from './store/shopping-list';
import { shoppingListItemsCleanup } from './store/shopping-list-items';
import { ongoingOrdersCleanup } from './store/ongoing-orders';
import { kpiCleanup } from './store/kpi';
import { purchaseReturnsCleanup } from './store/purchase-returns';
import { warehouseReceiptsCleanup } from './store/warehouse-receipts';

const routes: Routes = [
    {
        path: 'admin',
        canActivate: [adminGuard()],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: '',
        canActivate: [panelGuard()],
        children: [
            {
                path: '',
                canActivateChild: [userLoaded()],
                children: [
                    {
                        path: '',
                        redirectTo: 'dashboard',
                        pathMatch: 'full',
                    },
                    {
                        path: '',
                        canActivate: [UserNotLoggedInGuard],
                        children: [
                            ShellAuth.childRoutes([
                                {
                                    path: 'auth',
                                    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
                                },
                            ]),
                        ],
                    },
                    {
                        path: '',
                        canActivate: [UserLoggedInGuard],
                        children: [
                            ShellUser.childRoutes([
                                {
                                    path: '',
                                    canActivate: [shippingAddressesLoaded()],
                                    canDeactivate: [shippingAddressesCleanup(), mediaCleanup()],
                                    children: [
                                        {
                                            path: '',
                                            canActivate: [shoppingCartLoaded(), favouritesLoaded()],
                                            canDeactivate: [
                                                productsCleanup(),
                                                productCleanup(),
                                                productsPricingCleanup(),
                                                productsSubstitutesCleanup(),
                                                favouritesCleanup(),
                                                shoppingCartCleanup(),
                                                productCategoriesCleanup(),
                                                cleanupBlockades(),
                                                cleanupAddressAlert(),
                                                productFiltersCleanup,
                                                shoppingListsCleanup,
                                                shoppingListCleanup,
                                                shoppingListItemsCleanup,
                                                ongoingOrdersCleanup,
                                                kpiCleanup,
                                                purchaseReturnsCleanup,
                                                warehouseReceiptsCleanup,
                                            ],
                                            children: [
                                                {
                                                    path: 'dashboard',
                                                    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
                                                },
                                                {
                                                    path: 'products',
                                                    canActivate: [featureGuard('module.products')],
                                                    children: [
                                                        {
                                                            path: '',
                                                            canActivate: [addressAlertGuard()],
                                                            loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: 'categories',
                                                    canActivate: [featureGuard('nav.categories')],
                                                    loadChildren: () => import('./categories/categories.module').then((m) => m.CategoriesModule),
                                                },
                                                {
                                                    path: 'favourites',
                                                    canActivate: [featureGuard('module.favourites')],
                                                    loadChildren: () => import('./favourites/favourites.module').then((m) => m.FavouritesModule),
                                                },
                                                {
                                                    path: 'cart',
                                                    canActivate: [featureGuard('module.cart')],
                                                    children: [
                                                        {
                                                            path: '',
                                                            canActivate: [cartGuard()],
                                                            loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
                                                        },
                                                    ],
                                                },
                                                {
                                                    path: 'temp',
                                                    loadChildren: () => import('./temp/temp.module').then((m) => m.TempModule),
                                                },
                                                {
                                                    path: 'user-profile',
                                                    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
                                                },
                                                {
                                                    path: 'documents',
                                                    canActivate: [featureGuard('nav.documents')],
                                                    loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
                                                },
                                                {
                                                    path: 'document',
                                                    loadChildren: () => import('./document/document.module').then((m) => m.DocumentModule),
                                                },
                                                {
                                                    path: 'shopping-list',
                                                    canActivate: [featureGuard('shopping-lists')],
                                                    loadChildren: () => import('./shopping-list/shopping-list.module').then((m) => m.ShoppingListModule),
                                                },
                                                {
                                                    path: 'forecast',
                                                    canActivate: [featureGuard('nav.forecast')],
                                                    loadChildren: () => import('./forecast/forecast.module').then((m) => m.ForecastModule),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ]),
                            ShellSales.childRoutes([
                                {
                                    path: 'customers',
                                    loadChildren: () => import('./customers/customers.module').then((m) => m.CustomersModule),
                                },
                                {
                                    path: 'profile',
                                    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
                                },
                            ]),
                            {
                                path: 'logout',
                                loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
