import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectItems, selectLoaded, selectLoading, selectTotalItems } from '../selectors/ongoing-orders.selector';
import { ongoingOrdersActions } from '../actions/ongoing-orders.actions';
import { OngoingOrdersValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class OngoingOrdersFacade {
    private readonly store = inject(Store);

    totalItems$ = this.store.select(selectTotalItems);
    items$ = this.store.select(selectItems);
    loading$ = this.store.select(selectLoading);
    loaded$ = this.store.select(selectLoaded);

    getOngoingOrders(value: OngoingOrdersValue): void {
        this.store.dispatch(ongoingOrdersActions.getOngoingOrders({ value }));
    }

    clear(): void {
        this.store.dispatch(ongoingOrdersActions.clear());
    }
}
