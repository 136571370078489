import { MenuItem } from '../models';

export const menu: MenuItem[] = [
    {
        name: 'header.nav.dashboard',
        route: '/dashboard',
        feature: 'nav.dashboard',
    },
    {
        name: 'header.nav.products',
        route: '/products',
        feature: 'nav.products',
    },
    {
        name: 'header.nav.documents',
        route: '/documents',
        feature: 'nav.documents',
    },
    {
        name: 'header.nav.categories',
        route: '/categories',
        feature: 'nav.categories',
    },
    {
        name: 'header.nav.forecast',
        route: '/forecast',
        feature: 'nav.forecast',
    },
];
