import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { ProductCategories } from '../models/product-categories';
import { DefaultCategory } from '../models';

export const productCategoriesActions = createActionGroup({
    source: 'Product Categories',
    events: {
        'Get Product Categories': props<{ lang: Lang }>(),
        'Get Product Categories Success': props<{ categories: ProductCategories }>(),
        'Get Product Categories Error': emptyProps(),

        'Get Default Category': emptyProps(),
        'Get Default Category Success': props<{ defaultCategory: DefaultCategory }>(),
        'Get Default Category Error': emptyProps(),

        'Get Category Image': props<{ subcode: string }>(),
        'Get Category Image Success': props<{ subcode: string; blob: Blob }>(),
        'Get Category Image Error': props<{ subcode: string }>(),

        Clear: emptyProps(),
    },
});
